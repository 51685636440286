'use client';

import { createContext, useContext, useMemo } from 'react';
import { FormProvider, type UseFormReturn } from 'react-hook-form';

import type { FormState, FormData } from '../_actions/login';

export type LoginFormContextState = {
  pending?: boolean;
  error?: FormState | null;
};

const LoginFormContext = createContext<LoginFormContextState>({});

export function LoginFormProvider({
  pending,
  error,
  form,
  children,
}: React.PropsWithChildren<{
  pending?: boolean;
  error?: FormState | null;
  form: UseFormReturn<FormData>;
}>) {
  const value = useMemo(() => ({
    pending,
    error,
  }), [pending, error]);

  return (
    <LoginFormContext.Provider value={value}>
      <FormProvider {...form}>
        {children}
      </FormProvider>
    </LoginFormContext.Provider>
  );
}

export const useLoginForm = () => useContext(LoginFormContext);
