import { redirect, RedirectType } from 'next/navigation';

export type LoginState = {
  username: string;
  passwordPolicy: {
    minimumLength: number;
    requireLowercase: boolean;
    requireUppercase: boolean;
    requireNumbers: boolean;
    requireSymbols: boolean;
  };
  confirmationCodeDelivery?: {
    medium: 'email';
    destination: string;
  };
};

export function encodeState(state: Record<string, unknown>): string {
  return Buffer.from(JSON.stringify(state)).toString('base64');
}

export function decodeState(state?: string | null): Record<string, unknown> | null {
  if (state) {
    try {
      const decoded = JSON.parse(Buffer.from(state, 'base64').toString('utf-8'));

      if (typeof decoded === 'object') {
        return decoded;
      }
    } catch { /* ignore */ }
  }

  return null;
}

export function parseState(encodedState: string | null, fallbackUrl = '/login'): LoginState {
  const state = decodeState(encodedState);

  if (state && typeof state === 'object') {
    if ('username' in state && 'passwordPolicy' in state) {
      return state as LoginState;
    }
  }

  throw redirect(fallbackUrl, RedirectType.replace);
}
