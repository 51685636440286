import Alert from '@/components/Alert';

export type CommonErrorsProps = {
  error: 'NotAuthorizedException' | 'LimitExceededException' | 'InternalErrorException' | 'UnprocessableEntityException' | string;
};

export default function CommonErrors({
  error,
}: CommonErrorsProps) {
  if (error === 'NotAuthorizedException') {
    return (
      <Alert severity="error">
        <p>Credenciais incorretas</p>
      </Alert>
    );
  }

  if (error === 'InvalidPasswordException') {
    return (
      <Alert severity="error">
        <p>A senha não atende aos requisitos mínimos de segurança.</p>
      </Alert>
    );
  }

  if (error === 'LimitExceededException') {
    return (
      <Alert severity="error">
        <p>A sua conta foi temporariamente bloqueada por excesso de tentativas de login.</p>
        <p>Por favor, tente novamente após 15 minutos.</p>
      </Alert>
    );
  }

  if (error === 'InternalErrorException') {
    return (
      <Alert severity="error">
        <p>Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.</p>
      </Alert>
    );
  }

  if (error === 'UnprocessableEntityException') {
    return (
      <Alert severity="error">
        <p>Por favor, insira um e-mail válido.</p>
      </Alert>
    );
  }

  return null;
}
