import classNames from 'classnames';

export type AlertProps = React.PropsWithChildren<{
  severity?: 'info' | 'success' | 'error' | 'warning';
  className?: string;
}>;

export default function Alert({
  severity = 'info',
  className,
  children,
}: AlertProps) {
  return (
    <div
      role="alert"
      className={classNames('border-l-4 p-4 space-y-4', {
        'bg-blue-100 border-blue-500 text-blue-700': severity === 'info',
        'bg-lime-100 border-lime-500 text-lime-700': severity === 'success',
        'bg-orange-100 border-orange-500 text-orange-700': severity === 'warning',
        'bg-red-100 border-red-500 text-red-700': severity === 'error',
      }, className)}
    >
      {children}
    </div>
  );
}
