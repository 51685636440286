export type AuthenticationHeaderProps = {
  title: string;
  description?: string;
};

export default function AuthenticationHeader({
  title,
  description,
}: AuthenticationHeaderProps) {
  return (
    <div className="flex flex-col items-center gap-4 bg-[var(--theme-primary-color)] py-4 px-4">
      <p className="text-lg text-[var(--theme-primary-color-text)] font-semibold">
        {title}
      </p>
      {description && (
        <p className="text-md text-[var(--theme-primary-color-text)] text-center">
          {description}
        </p>
      )}
    </div>
  );
}
