'use client';

import Link from 'next/link';
import { useFormContext } from 'react-hook-form';
import { useSearchParams } from 'next/navigation';
import classNames from 'classnames';

import Alert from '@/components/Alert';

import { decodeState } from '../_helpers/state';
import { useLoginForm } from './_components/LoginFormProvider';
import AuthenticationHeader from '../_components/AuthenticationHeader';
import CommonErrors from '../_components/CommonErrors';
import SubmitButton from '../_components/SubmitButton';
import { useState } from 'react';

import { Eye, EyeOff } from 'lucide-react';
import { Button } from '@/components/ui/button';

export default function LoginPage() {
  const { formState, register } = useFormContext();
  const { pending, error } = useLoginForm();
  const searchParams = useSearchParams();

  const state = decodeState(searchParams.get('state'));

  const [showPassword, setShowPassword] = useState(false);

  return (
    <div>
      <AuthenticationHeader
        title="Olá, seja bem-vindo!"
      />

      {error && (
        <CommonErrors
          error={error.code}
        />
      )}

      {(state && state.message === 'PasswordResetSucceeded') && (
        <Alert severity="success">
          <p>Sua senha foi redefinida com sucesso. Faça login para continuar.</p>
        </Alert>
      )}

      <div className="p-4 space-y-4">
        <div className="space-y-1">
          <label htmlFor="username" className="font-semibold leading-6">
            E-mail
          </label>

          <input
            type="text"
            id="username"
            className={classNames('py-2 px-4 w-full border border-slate-300 rounded-md', {
              'ring-red-500 ring-1 focus:outline-none focus:ring-2 bg-red-50 rounded-b-none': formState.errors.username,
            })}
            autoComplete="username"
            placeholder="email@empresa.com"
            {...register('username', {
              required: {
                value: true,
                message: 'Por favor, insira seu e-mail',
              },
            })}
          />

          {formState.errors.username && (
            <div className="ring-1 ring-red-500 bg-red-100 rounded-b-md py-0.5 px-2">
              <p className="text-red-500 text-sm font-semibold">
                {formState.errors.username.message as string}
              </p>
            </div>
          )}
        </div>

        <div className="space-y-1">
          <div className="relative">
            <label htmlFor="password" className="font-semibold leading-6">
              Senha
            </label>

            <div className="relative">
              <input
                id="password"
                type={showPassword ? 'text' : 'password'}
                className={classNames('py-2 px-4 block w-full border border-slate-300 rounded-md', {
                  'ring-red-500 ring-1 focus:outline-none focus:ring-2 bg-red-50 rounded-b-none': formState.errors.password,
                })}
                autoComplete="password"
                placeholder="Insira sua senha"
                {...register('password', {
                required: {
                  value: true,
                  message: 'Por favor, insira sua senha',
                },
              })}
              />
              <Button
                type="button"
                variant="link"
                value={showPassword.toString()}
                onClick={() => setShowPassword((prev) => !prev)}
                className="absolute top-0 end-0 pt-3 px-4 rounded-e-md focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:ring-0 focus-visible:ring-offset-0"
              >
                {showPassword ? <Eye /> : <EyeOff />}
              </Button>
            </div>

            {formState.errors.password && (
              <div className="ring-1 ring-red-500 bg-red-100 rounded-b-md py-0.5 px-2">
                <p className="text-red-500 text-sm font-semibold">
                  {formState.errors.password.message as string}
                </p>
              </div>
            )}
          </div>

          <div className="flex justify-end">
            <Link
              href="/reset-password"
              className="text-sm font-semibold text-[var(--theme-primary-color)] hover:text-[var(--theme-secondary-color)]"
            >
              Esqueceu a senha?
            </Link>
          </div>
        </div>

        <SubmitButton disabled={pending}>
          {pending ? 'Aguarde...' : 'Entrar'}
        </SubmitButton>
      </div>
    </div>
  );
}
