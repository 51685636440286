import { Button } from '@/components/ui/button';
import classNames from 'classnames';

export type SubmitButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

export default function SubmitButton({
  className,
  children,
  ...props
}: SubmitButtonProps) {
  return (
    <Button
      type="submit"
      className={classNames('flex w-full justify-center h-15 text-base rounded-md bg-[var(--theme-primary-color)] p-3 font-semibold leading-6 text-white shadow-sm hover:bg-[var(--theme-secondary-color)] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-[var(--theme-secondary-color)] focus-visible:ring-0 focus-visible:ring-offset-0 cursor-pointer', {
        'opacity-50 cursor-auto': props.disabled,
      }, className)}
      {...props}
    >
      {children}
    </Button>
  );
}
